import { render, staticRenderFns } from "./VolunteerCoupon.vue?vue&type=template&id=073dc37a&scoped=true&"
import script from "./VolunteerCoupon.vue?vue&type=script&lang=js&"
export * from "./VolunteerCoupon.vue?vue&type=script&lang=js&"
import style0 from "./VolunteerCoupon.vue?vue&type=style&index=0&id=073dc37a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "073dc37a",
  null
  
)

export default component.exports
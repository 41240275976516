<template>
  <!--消費趨勢-->
  <grid-item :static="layoutItem.static" :x="layoutItem.x" :y="layoutItem.y" :w="layoutItem.w" :h="layoutItem.h" :i="layoutItem.i">
    <card type="chart">
      <template slot="header">
        <div class="row">
          <div class="col-sm-6" :class="'text-left'">
            <h5 class="card-category">消費趨勢分析</h5>
            <h3 class="card-title">單月消費金額 <small v-if="summary">({{summary}})</small></h3>
          </div>
          <div class="col-sm-6 d-flex d-sm-block">
            <!--
            <base-button @click="togglePinCard" size="sm" class="btn float-right border-0" icon round
                         :title="layoutItem.static ? '解除固定卡片' : '固定卡片'"
                         :simple="layoutItem.static ? null : 'simple'">
              <i class="tim-icons icon-pin"></i>
            </base-button>
            <base-button title="移除卡片" @click="removeCardItem" simple size="sm" class="btn float-right border-0" icon round>
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>-->
            <div class="btn-group btn-group-toggle"
                 :class="'float-right'"
                 data-toggle="buttons">
              <label v-for="(option, index) in bigLineChartCategories"
                     :key="option.name"
                     class="btn btn-sm btn-primary btn-simple"
                     :class="{ active: bigLineChart.activeIndex === index }"
                     :id="index">
                <input type="radio"
                       @click="initBigChart(index)"
                       name="options"
                       autocomplete="off"
                       :checked="bigLineChart.activeIndex === index" />
                <span class="d-none d-sm-block">{{ option.name }}</span>
                <span class="d-block d-sm-none">
                  <i :class="option.icon"></i>
                </span>
              </label>
            </div>
          </div>
        </div>
      </template>
      <div class="chart-area">
        <line-chart style="height: 100%"
                    ref="bigChart"
                    :chart-data="bigLineChart.chartData"
                    :gradient-colors="bigLineChart.gradientColors"
                    :gradient-stops="bigLineChart.gradientStops"
                    :extra-options="bigLineChart.extraOptions">
        </line-chart>
      </div>
    </card>
  </grid-item>
</template>
<script>
  import { GridItem } from 'vue-grid-layout';
  import LineChart from '@/components/Charts/LineChart';
  import * as chartConfigs from '@/components/Charts/config';
  import config from '@/config';

  let bigChartLabels = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];
  let bigChartDatasetOptions = {
    fill: true,
    borderColor: config.colors.primary,
    borderWidth: 2,
    borderDash: [],
    borderDashOffset: 0.0,
    pointBackgroundColor: config.colors.primary,
    pointBorderColor: 'rgba(255,255,255,0)',
    pointHoverBackgroundColor: config.colors.primary,
    pointBorderWidth: 20,
    pointHoverRadius: 4,
    pointHoverBorderWidth: 15,
    pointRadius: 4,
  };

  export default {
    components: {
      GridItem,
      LineChart,
    },
    data() {
      return {
        summary: '',
        bigChartData: [],
        bigLineChart: {
          activeIndex: 0,
          chartData: {
            datasets: [{
              ...bigChartDatasetOptions,
              data: []
            }],
            labels: bigChartLabels
          },
          extraOptions: chartConfigs.purpleChartOptions,
          gradientColors: config.colors.primaryGradient,
          gradientStops: [1, 0.4, 0],
          categories: []
        },
      };
    },
    props: {
      layoutItem: {
        type: Object,
        default: null
      }
    },
    computed: {
      bigLineChartCategories() {
        return [{ name: 'ALL', icon: 'tim-icons icon-single-02' }, { name: '今年度', icon: 'tim-icons icon-gift-2' }, { name: '去年度', icon: 'tim-icons icon-tap-02' }, { name: '前年度', icon: 'tim-icons icon-tap-02' }];
      },
    },
    methods: {
      initBigChart(index) {
        let chartData = {
          datasets: [{
            ...bigChartDatasetOptions,
            data: (index == 0 && this.bigChartData.length) ? this.bigChartData[index].map(item => item.value) : this.bigChartData[index]
          }],
          labels: (index == 0 && this.bigChartData.length) ? this.bigChartData[index].map(item => item.label) : bigChartLabels
        };
        this.$refs.bigChart.updateGradients(chartData);
        this.bigLineChart.chartData = chartData;
        this.bigLineChart.activeIndex = index;
        // 小計
        if (this.bigChartData.length) {
          const sumTotalPrice = ((index == 0 && this.bigChartData.length) ? this.bigChartData[index].map(item => item.value) : this.bigChartData[index]).reduce((prev, curr) => prev + curr, 0);
          this.summary = `累計消費金額 ${Number(sumTotalPrice).toLocaleString("zh-TW", { style: "currency", currency: "TWD" })}`;
        }
      },
      togglePinCard() {
        this.$emit('refreshLayout', this.layoutItem.i, 'pin', !this.layoutItem.static);
      },
      removeCardItem() {
        this.$emit('refreshLayout', this.layoutItem.i, 'remove');
      },
    },
    mounted() {
      this.initBigChart(0);
    },
};
</script>
<style></style>

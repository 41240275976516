<template>
  <!-- 會員資料編輯 -->
  <grid-item :static="layoutItem.static" :x="layoutItem.x" :y="layoutItem.y" :w="layoutItem.w" :h="layoutItem.h" :i="layoutItem.i">
    <card>
      <h4 slot="header" class="card-title">
        <i class="el-icon-user"></i>
        <strong>{{volunteer.name}}</strong>
        基本資料
        <base-button @click="togglePinCard" icon round size="sm" class="btn float-right border-0"
                     :title="layoutItem.static ? '解除固定卡片' : '固定卡片'"
                     :simple="layoutItem.static ? null : 'simple'">
          <i class="tim-icons icon-pin"></i>
        </base-button>
        <base-button @click="readonly=!readonly"
                     title="編輯資料" icon round size="sm" class="btn float-right border-0"
                     :simple="readonly ? 'simple' : null">
          <i class="tim-icons icon-pencil"></i>
        </base-button>
        <!--<base-button title="移除卡片" @click="removeCardItem" simple size="sm" class="btn float-right border-0" icon round>
          <i class="tim-icons icon-simple-remove"></i>
        </base-button>-->
        <!--<base-button title="儲存資料" simple icon round size="sm" native-type="submit" type="primary" class="btn float-right border-0">
          <i class="tim-icons icon-check-2"></i>
        </base-button>-->
      </h4>
      <form @submit.prevent="updateProfile">
        <div class="row">
          <div class="col-md-3">
            <base-input type="text"
                        label="帳號"
                        :disabled="true"
                        placeholder="帳號"
                        v-model="volunteer.account">
            </base-input>
          </div>
          <div class="col-md-3">
            <base-input type="text"
                        label="姓名"
                        placeholder="姓名"
                        :disabled="readonly"
                        v-model="volunteer.name">
            </base-input>
          </div>
          <div class="col-md-6">
            <base-input type="email"
                        label="Email"
                        placeholder="Email"
                        :disabled="readonly"
                        v-model="volunteer.email">
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-5">
            <base-input type="text"
                        label="手機號碼"
                        placeholder="手機號碼"
                        :disabled="readonly"
                        v-model="volunteer.mobile">
            </base-input>
          </div>
          <div class="col-md-3">
            <div class="form-group has-label">
              <label> 生日  </label>
              <div class="mb-0">
                <el-date-picker v-model="volunteer.birthday"
                                type="date"
                                placeholder="生日"
                                :disabled="readonly"
                                format="yyyy/MM/dd">
                </el-date-picker>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group has-label">
              <label> 性別  </label>
              <div class="mb-0">
                <el-select :disabled="readonly" v-model="volunteer.gender" placeholder="性別">
                  <el-option label="先生" value="先生"></el-option>
                  <el-option label="小姐" value="小姐"></el-option>
                  <el-option label="其他" value="其他"></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-5">
            <base-input type="text"
                        label="縣市區域"
                        placeholder="縣市區域"
                        :disabled="readonly"
                        v-model="volunteer.cityArea">
            </base-input>
          </div>
          <div class="col-md-3">
            <base-input type="text"
                        :disabled="true"
                        label="年齡"
                        placeholder="年齡"
                        v-model="volunteer.age">
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input type="text"
                        :disabled="true"
                        label="會員身份"
                        placeholder="會員身份"
                        v-model="volunteer.vipLevel">
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-5">
            <base-input type="text"
                        label="地址"
                        placeholder="地址"
                        :disabled="readonly"
                        v-model="volunteer.address">
            </base-input>
          </div>
          <div class="col-md-3">
            <div class="form-group has-label">
              <label> 收件時段  </label>
              <div class="mb-0">
                <el-select :disabled="readonly" v-model="volunteer.reciveTime" placeholder="收件時段">
                  <el-option label="早上 8:00~12:00" value="1"></el-option>
                  <el-option label="下午 12:00~17:00" value="2"></el-option>
                  <el-option label="晚上 17:00~20:00" value="3"></el-option>
                  <el-option label="不指定" value="4"></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group has-label">
              <label> 有效期限  </label>
              <div class="mb-0">
                <el-date-picker v-model="volunteer.vipEndTime"
                                :disabled="readonly || volunteer.vipLevel==1"
                                type="date"
                                placeholder="有效期限"
                                format="yyyy/MM/dd">
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-5">
            <div class="form-group has-label">
              <label> 註冊日期 <el-divider direction="vertical" /> 最近更新 </label>
              <div v-if="volunteer" class="mb-0">
                <span>{{moment(String(volunteer.createTime)).add(8,'hours').format('YYYY/MM/DD')}} <small>({{ moment(String(volunteer.createTime)).add(8,'hours').fromNow() }})</small></span>
                <el-divider direction="vertical" />
                <small class="text-nowrap" v-if="volunteer">{{moment(String(volunteer.updateTime)).add(8,'hours').format('YYYY/MM/DD HH:mm')}}</small>
              </div>
            </div>
          </div>
          <div class="col-md-7">
            <div class="form-group has-label">
              <label> 使用限制  </label>
              <div class="mb-0">
                <base-checkbox inline :disabled="readonly" :checked="volunteer.isStopCashOnDelivery">禁止貨到付款</base-checkbox>
                <base-checkbox inline :disabled="readonly" :checked="volunteer.isStopPayByCash">禁止現金自取</base-checkbox>
                <base-checkbox inline :disabled="readonly" :checked="volunteer.disableTime">加入黑名單</base-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <base-input label="出貨備註">
              <textarea class="form-control"
                        placeholder="出貨單上的備註，例：管理員可代收，假日不在家。"
                        :disabled="readonly"
                        v-model="volunteer.orderRemarks">
                </textarea>
            </base-input>
          </div>
          <div class="col-md-6">
            <base-input label="會員備註">
              <textarea class="form-control"
                        placeholder="會員備註，例：不吃木瓜"
                        :disabled="readonly"
                        v-model="volunteer.remarks">
                </textarea>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <base-input label="內部備註">
              <el-table stripe highlight-current-row height="200" v-loading="$apollo.loading" :data="volunteer.internalRemarkRecords">
                <el-table-column label="備註">
                  <template slot-scope="scope">
                    <span>{{scope.row.remark}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="建立時間"
                                 width="220">
                  <template slot-scope="scope">
                    <span>{{ moment(String(scope.row.createTime)).add(8,'hours').format('YYYY/MM/DD HH:mm') }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="建立人員"
                                 width="220">
                  <template slot-scope="scope">
                    <span>{{scope.row.createUser}}</span>
                  </template>
                </el-table-column>
              </el-table>
            </base-input>
          </div>
        </div>
      </form>
    </card>
  </grid-item>
</template>
<script>
  import { GridItem } from 'vue-grid-layout';
  import { BaseCheckbox } from 'src/components/index';

  export default {
    components: {
      GridItem,
      BaseCheckbox,
    },
    data() {
      return {
        volunteer: '',
        readonly: true,
      };
    },
    props: {
      layoutItem: {
        type: Object,
        default: null
      }
    },
    methods: {
      togglePinCard() {
        this.$emit('refreshLayout', this.layoutItem.i, 'pin', !this.layoutItem.static);
      },
      removeCardItem() {
        this.$emit('refreshLayout', this.layoutItem.i, 'remove');
      },
    },
  };
</script>
<style></style>

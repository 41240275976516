<template>
  <card type="chart">
    <template slot="header">
      <h5 class="card-category">消費優惠方式統計</h5>
      <h3 class="card-title">
        <i class="tim-icons icon-bag-16 text-danger "></i> {{ sumTotal }}
      </h3>
    </template>
    <div class="chart-area">
      <bar-chart style="height: 100%"
                 :chart-data="barChart.chartData"
                 :gradient-stops="barChart.gradientStops"
                 :extra-options="barChart.extraOptions">
      </bar-chart>
    </div>
  </card>
</template>
<script>
  import BarChart from '@/components/Charts/BarChart';
  import * as chartConfigs from '@/components/Charts/config';
  import config from '@/config';
  import { CONSUMPTION_DISCOUNT_QUERY } from 'src/services/graphql';

  let chartLabels = ['優惠劵', '購物金', '紅利點數'];
  let barChartDatasetOptions = {
    label: '總額',
    fill: true,
    borderWidth: 2,
    borderDash: [],
    borderDashOffset: 0.0,
    backgroundColor: [
      config.colors.danger,
      config.colors.orange,
      config.colors.info
    ],
    borderColor: [
      config.colors.danger,
      config.colors.orange,
      config.colors.info
    ],
  };

  export default {
    components: {
      BarChart,
    },
    data() {
      return {
        barChart: {
          chartData: {
            labels: chartLabels,
            datasets: [{
              ...barChartDatasetOptions,
              data: []
            }]
          },
          extraOptions: chartConfigs.barChartOptionsGradient,
        },
      };
    },
    props: {
      volunteer: {
        type: Object,
        default: null
      }
    },
    computed: {
      sumTotal() {
        return this.barChart.chartData.datasets[0].data.reduce((prev, curr) => prev + curr, 0).toLocaleString("zh-TW", { style: "currency", currency: "TWD" });
      }
    },
    apollo: {
      consumptionDiscount: {
        query: CONSUMPTION_DISCOUNT_QUERY,
        variables() {
          return {
            id: this.volunteer.id,
          }
        },
        skip() { return !this.volunteer; },
        update: result => result.analytics.consumptionDiscount,
      }
    },
    watch: {
      consumptionDiscount: function (val) {
        this.barChart.chartData = {
          labels: chartLabels,
          datasets: [{
            ...barChartDatasetOptions,
            data: [val.coupon, val.bonus, val.redPoint]
          }]
        };
      }
    },
};
</script>
<style></style>

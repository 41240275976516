<template>
  <card type="chart">
    <template slot="header">
      <h5 class="card-category">消費類型分析</h5>
      <h3 class="card-title">
        <i class="tim-icons icon-delivery-fast text-info "></i> {{ chartDataSumTotal }}
      </h3>
    </template>
    <div class="chart-area">
      <bar-chart style="height: 100%"
                 :chart-data="blueBarChart.chartData"
                 :gradient-stops="blueBarChart.gradientStops"
                 :extra-options="blueBarChart.extraOptions">
      </bar-chart>
    </div>
  </card>
</template>
<script>
  import BarChart from '@/components/Charts/BarChart';
  import * as chartConfigs from '@/components/Charts/config';
  import config from '@/config';

  let blueBarChartLabels = ['快速到貨', '冷凍專區', '單品', '粥寶寶', '水果箱', '蔬菜箱'];
  let blueBarChartDatasetOptions = {
    label: '總額',
    fill: true,
    borderWidth: 2,
    borderDash: [],
    borderDashOffset: 0.0,
    backgroundColor: [
      'rgba(255, 99, 132, 0.2)',
      'rgba(255, 159, 64, 0.2)',
      'rgba(255, 205, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(153, 102, 255, 0.2)'
    ],
    borderColor: [
      'rgb(255, 99, 132)',
      'rgb(255, 159, 64)',
      'rgb(255, 205, 86)',
      'rgb(75, 192, 192)',
      'rgb(54, 162, 235)',
      'rgb(153, 102, 255)'
    ],
  };

  export default {
    components: {
      BarChart,
    },
    data() {
      return {
        blueChartData: [],
        blueBarChart: {
          chartData: {
            labels: blueBarChartLabels,
            datasets: [{
              ...blueBarChartDatasetOptions,
              data: []
            }]
          },
          extraOptions: chartConfigs.barChartOptionsGradient,
          gradientColors: config.colors.primaryGradient,
          gradientStops: [1, 0.2, 0]
        },
      };
    },
    computed: {
      chartDataSumTotal() {
        return this.blueBarChart.chartData.datasets[0].data.reduce((prev, curr) => prev + curr, 0).toLocaleString("zh-TW", { style: "currency", currency: "TWD" });
      },
    },
    methods: {
    },
    mounted() {
    },
    watch: {
      blueChartData: function (val) {
        this.blueBarChart.chartData = {
          labels: val.map(item => item.label),
          datasets: [{
            ...blueBarChartDatasetOptions,
            data: val.map(item => item.sumTotal),
          }]
        };
      }
    },
};
</script>
<style></style>

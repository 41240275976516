<template>
  <div class="content">
    <div class="header text-center"><h3 class="title">會員升等歷程</h3></div>
    <div class="block">
      <el-timeline :reverse="true">
        <el-timeline-item v-for="(activity, index) in activities"
                          :key="index"
                          :icon="activity.icon"
                          :type="activity.type"
                          :color="activity.color"
                          :size="activity.size"
                          :timestamp="activity.timestamp"
                          placement="top">

          <card style="width: 20rem;" shadow="always">
            <h4 class="card-title">{{activity.content}}</h4>
            <h6 v-if="activity.extra" class="card-subtitle mb-2 text-muted">訂單編號：{{activity.extra}}</h6>
            <p class="card-text">
              <span>{{ moment(String(activity.startTime)).format('YYYY/MM/DD HH:mm A') }} <small>({{ moment(String(activity.startTime)).fromNow() }})</small></span>
              <span v-if="activity.endTime">
                ~ {{ moment(String(activity.endTime)).format('YYYY/MM/DD') }}
              </span>
            </p>
          </card>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>
<script>
  import { VOLUNTEER_VIPRECORD_QUERY } from 'src/services/graphql';
  import * as moment from 'moment';

  export default {
    components: {
    },
    data() {
      return {
        activities: [],
      };
    },
    props: {
      volunteer: {
        type: Object,
        default: null
      }
    },
    apollo: {
      vipRecords: {
        query: VOLUNTEER_VIPRECORD_QUERY,
        variables() {
          return {
            id: this.volunteer.id,
          }
        },
        skip() { return !this.volunteer; },
        update: result => result.volunteers.vipRecords,
      }
    },
    watch: {
      vipRecords: function (val) {
        console.log('vipRecords => ' + val);
        this.activities = val.map(item => ({
          content: ('成為' + item.vipLevelName),
          timestamp: moment(String(item.startTime)).format('YYYY/MM/DD'),
          startTime: item.startTime,
          endTime: item.endTime,
          extra: item.orderId,
          size: 'large',
          type: (item.vipLevelId == 11) ? 'warning' : (item.vipLevelId == 12) ? 'danger' : null,

        }));

        this.activities.splice(0, 0, {
          content: ('加入會員'),
          timestamp: moment(String(this.volunteer.createTime)).add(8, 'hours').format('YYYY/MM/DD'),
          startTime: moment(String(this.volunteer.createTime)).add(8, 'hours'),
        });

        if (this.volunteer.firstOrderTime != null) {
          this.activities.splice(1, 0, {
            content: ('首次消費'),
            timestamp: moment(String(this.volunteer.firstOrderTime)).add(8, 'hours').format('YYYY/MM/DD'),
            startTime: moment(String(this.volunteer.firstOrderTime)).add(8, 'hours'),
            type: 'success'
          });
        }
        
        if (this.volunteer.lastOrderTime != null) {
          this.activities.splice(this.activities.length, 0, {
            content: ('最近一次消費'),
            timestamp: moment(String(this.volunteer.lastOrderTime)).add(8, 'hours').format('YYYY/MM/DD'),
            startTime: moment(String(this.volunteer.lastOrderTime)).add(8, 'hours'),
            type: 'primary'
          });
        }
        
      },
    }
  };
</script>
<style></style>

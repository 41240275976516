<template>
  <card card-body-classes="table-full-width">
    <div>
      <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
        <base-input>
          <div>
            <el-input type="search"
                      class="mb-3 search-input"
                      clearable
                      prefix-icon="el-icon-search"
                      placeholder="搜尋"
                      v-model="searchQuery"
                      aria-controls="datatables">
            </el-input>
          </div>
        </base-input>
        <el-select class="select-primary mb-3 pagination-select"
                   v-model="pagination.perPage"
                   placeholder="Per page">
          <el-option class="select-primary"
                     v-for="item in pagination.perPageOptions"
                     :key="item"
                     :label="item"
                     :value="item">
          </el-option>
        </el-select>
      </div>
      <el-table stripe highlight-current-row height="400" v-loading="$apollo.loading" :data="queriedData">
        <el-table-column label="訂單編號"
                         width="140">
          <template slot-scope="scope">
            <el-link :href="goxDomain + '/GoX/Orders?fun_id=3034&keyword=' + scope.row.orderNumber + '&direction=-1&fuseSearchOff=true'" target="greenbox">{{ scope.row.orderNumber }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="計算"
                         width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.point>0">+{{ Number(scope.row.point).toLocaleString() }}</span>
            <span v-else>{{ Number(scope.row.point).toLocaleString() }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark"
                         label="備註">
        </el-table-column>
        <el-table-column label="建立時間(人員)"
                         width="140">
          <template slot-scope="scope">
            <span>{{ moment(String(scope.row.createTime)).format('YYYY/MM/DD') }}</span><br />
            <small>{{ moment(String(scope.row.createTime)).format('HH:mm A') }}</small>
            <span v-if="scope.row.createUserName"> {{ scope.row.createUserName }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div slot="footer"
         class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
      <div class="">
        <p class="card-category">
          顯示 {{ from + 1 }} ~ {{ to }}，共 {{ total }} 筆
        </p>
      </div>
      <base-pagination class="pagination-no-border"
                       v-model="pagination.currentPage"
                       :per-page="pagination.perPage"
                       :total="total">
      </base-pagination>
    </div>
  </card>
</template>
<script>
  import { BasePagination } from 'src/components';
  import Fuse from 'fuse.js';
  import config from '@/config';
  import { VOLUNTEER_REDPOINT_QUERY } from 'src/services/graphql';

  export default {
    components: {
      BasePagination,
    },
    computed: {
      /***
       * Returns a page from the searched data or the whole data. Search is performed in the watch section below
       */
      queriedData() {
        let result = this.tableData;
        if (this.searchedData.length > 0) {
          result = this.searchedData;
        }
        return result.slice(this.from, this.to);
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      total() {
        return this.searchedData.length > 0
          ? this.searchedData.length
          : this.tableData.length;
      }
    },
    data() {
      return {
        goxDomain: config.domain.gox,
        pagination: config.pagination,
        searchQuery: '',
        propsToSearch: ['remark', 'orderNumber'],
        tableData: [],
        searchedData: [],
        fuseSearch: null,
      };
    },
    props: {
      volunteer: {
        type: Object,
        default: null
      }
    },
    methods: {},
    mounted() {},
    apollo: {
      redpoint: {
        query: VOLUNTEER_REDPOINT_QUERY,
        variables() {
          return {
            id: this.volunteer.id,
          }
        },
        skip() { return !this.volunteer; },
        update: result => result.volunteers.redpoint,
      },
    },
    watch: {
      /**
       * Searches through the table data by a given query.
       * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
       * @param value of the query
       */
      searchQuery(value) {
        let result = this.tableData;
        if (value !== '') {
          result = this.fuseSearch.search(this.searchQuery).map(a => a.item);
          console.log(result);
        }
        this.searchedData = result;
      },
      tableData: function (val) {
        if (val && this.fuseSearch == null) {
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
        }
      },
      redpoint: function (val) {
        console.log('redpoint => ' + val);
        this.tableData = val;
      },
    }
  };
</script>
<style scoped>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content{
  font-size: inherit !important;
}
</style>

<template>
  <!-- 訂購紀錄 -->
  <grid-item :static="layoutItem.static" :x="layoutItem.x" :y="layoutItem.y" :w="layoutItem.w" :h="layoutItem.h" :i="layoutItem.i">
    <card card-body-classes="table-full-width">
      <h4 slot="header" class="card-title">
        <i class="el-icon-user"></i>
        <strong>{{volunteer.name}}</strong>
        訂購紀錄
        <small v-if="summary">({{summary}})</small>
        <base-button @click="togglePinCard" size="sm" class="btn float-right border-0" icon round
                     :title="layoutItem.static ? '解除固定卡片' : '固定卡片'"
                     :simple="layoutItem.static ? null : 'simple'">
          <i class="tim-icons icon-pin"></i>
        </base-button>
        <!--<base-button title="移除卡片" @click="removeCardItem" simple size="sm" class="btn float-right border-0" icon round>
          <i class="tim-icons icon-simple-remove"></i>
        </base-button>-->
      </h4>
      <div>
        <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <base-input>
            <div>
              <el-input type="search"
                        class="mb-3 search-input"
                        clearable
                        prefix-icon="el-icon-search"
                        placeholder="搜尋"
                        v-model="searchQuery"
                        aria-controls="datatables">
              </el-input>
            </div>
          </base-input>
          <el-button-group>
            <el-button @click="filterData(0)">ALL</el-button>
            <el-button @click="filterData(1)">處理中</el-button>
            <el-button @click="filterData(2)">已出貨</el-button>
            <el-button @click="filterData(3)">已送達</el-button>
            <el-button @click="filterData(4)">已取消</el-button>
            <el-button @click="filterData(5)">等家寶寶</el-button>
          </el-button-group>
          <el-select class="select-primary mb-3 pagination-select"
                     v-model="pagination.perPage"
                     placeholder="Per page">
            <el-option class="select-primary"
                       v-for="item in pagination.perPageOptions"
                       :key="item"
                       :label="item"
                       :value="item">
            </el-option>
          </el-select>
        </div>
        <el-table stripe highlight-current-row height="400" v-loading="$apollo.loading" :data="queriedData">
          <el-table-column type="expand">
            <template slot-scope="scope">
              <span v-if="scope.row.remarks"><small>出貨備註：</small>{{ scope.row.remarks }}<br /></span>
              <span v-if="scope.row.remarkFromAdmin"><small>倉庫備註：</small>{{ scope.row.remarkFromAdmin }}<br /></span>
              <span v-if="scope.row.customerServiceRemark"><small>客服備註：</small>{{ scope.row.customerServiceRemark }}<br /></span>
            </template>
          </el-table-column>
          <el-table-column label="訂單編號"
                           width="140">
            <template slot-scope="scope">
              <strong class="text-center">
                <small>訂單編號</small><br />
                <el-link :href="goxDomain + '/GoX/Orders?fun_id=3034&keyword=' + scope.row.orderNumber + (scope.row.isXmas? '&IsXmas=true' : '&IsXmas=false')  + '&IsBaby=0&direction=-1&fuseSearchOff=true'" target="greenbox">{{ scope.row.orderNumber }}</el-link>
              </strong>
              <br />
              <span v-if="scope.row.blackCatNum" class="text-center">
                <small>物流編號</small><br />
                <el-link v-if="scope.row.deliveryType == 3" :href="'https://www.t-cat.com.tw/inquire/TraceDetail.aspx?ReturnUrl=Trace.aspx&BillID=' + scope.row.blackCatNum" target="tracking">{{ scope.row.blackCatNum }}</el-link>
                <el-link v-else-if="scope.row.deliveryType == 4" :href="'https://www.famiport.com.tw/Web_Famiport/page/process.aspx'" target="tracking">{{ scope.row.blackCatNum }}</el-link>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="收件人"
                           width="170">
            <template slot-scope="scope">
              <strong>{{ scope.row.receiverName }}</strong>
              <br />
              <span>{{ scope.row.receiverMobile }}</span>
              <br />
              <span>{{ scope.row.address }}</span>
            </template>
          </el-table-column>
          <el-table-column label="訂單商品"
                           width="220">
            <template slot-scope="scope">
              <strong class="text-truncate">{{ scope.row.orderDetails[0].product.name }}</strong>
              <br />
              <span>
                規格: <span v-if="scope.row.sub_week> 0">{{ scope.row.sub_week }} / </span>
                {{ scope.row.orderDetails[0].productDetail.amt }} {{ scope.row.orderDetails[0].productDetail.module }}
              </span>
              <small v-if="scope.row.orderDetails[0].productDetail.detail" class="text-truncate">{{ scope.row.orderDetails[0].productDetail.detail }}</small>
              <br />
              <span>數量: {{ scope.row.orderDetails[0].amount }}</span>
              <br />
              <span>小計: {{ Number(scope.row.orderDetails[0].price).toLocaleString() }} / {{ Number(scope.row.orderDetails[0].productDetail.price * scope.row.orderDetails[0].amount).toLocaleString() }}</span>
            </template>
          </el-table-column>
          <el-table-column label="出貨日期"
                           width="130">
            <template slot-scope="scope">
              <span v-if="moment(String(scope.row.ordersTime)).year() == 9999" class="text-warning">暫不出貨</span>
              <span v-else>
                {{ moment(String(scope.row.ordersTime)).format('YYYY/MM/DD') }}
                <br />
                <small>{{ moment(String(scope.row.ordersTime)).format('ddd') }}</small>
                <small>{{ reciveTimeChoiceType(scope.row.reciveTimeChoice) }}</small>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="訂購時間"
                           width="130">
            <template slot-scope="scope">
              <span>{{ moment(String(scope.row.createTime)).add(8,'hours').format('YYYY/MM/DD') }}</span>
              <br />
              <small>{{ moment(String(scope.row.createTime)).add(8,'hours').format('HH:mmA') }}</small>
              <small>({{ ordersFromType(scope.row.ordersFrom) }})</small>
              <!--<el-badge :value="ordersFromType(scope.row.ordersFrom)" class="item" type="info" />-->
            </template>
          </el-table-column>
          <el-table-column label="物流狀態"
                           width="130">
            <template slot-scope="scope">
              <strong v-if="scope.row.direction == 3" class="text-danger">已取消</strong>
              <strong v-else-if="scope.row.isBaby == true && scope.row.isPay == 2 && moment(String(scope.row.ordersTime)) < Date.now()">已送達</strong>
              <strong v-else>{{ scope.row.ordersStates }}</strong>
              <br />
              <el-badge :value="temperatureLayer(scope.row.temperatureLayer)" class="item" type="danger" />
              <el-badge :value="deliveryType(scope.row.deliveryType)" class="item" type="primary" />
              <small v-if="scope.row.blackCatStatus" class="text-danger"><br />({{ scope.row.blackCatStatus }})</small>
            </template>
          </el-table-column>
          <el-table-column label="金流狀態"
                           width="140">
            <template slot-scope="scope">
              <span>{{ scope.row.paymentType }}</span><br />
              <span v-if="scope.row.isPay == 2">(已付款)</span>
              <strong v-if="scope.row.isPay == 1" class="text-danger">(未付款)</strong><br />
              <el-badge v-if="scope.row.paymentType == '信用卡' && scope.row.paymentGateWayType == 1" :value="'綠界'" class="item" type="success" />
              <el-badge v-if="scope.row.paymentType == '信用卡' && scope.row.paymentGateWayType == 2" :value="'TapPay'" class="item" type="warning" />
            </template>#f4a83b
          </el-table-column>
          <el-table-column label="總金額"
                           align="right">
            <template slot-scope="scope">
              <span>$ {{ Number(scope.row.totalPrice).toLocaleString() }}</span>
            </template>
          </el-table-column>
          <!--<el-table-column align="right" label="編輯">
            <div slot-scope="props">
              <base-button @click.native="handleEdit(props.$index, props.row)"
                           class="edit btn-link"
                           type="warning"
                           size="sm"
                           icon>
                <i class="tim-icons icon-pencil"></i>
              </base-button>
              <base-button @click.native="handleDelete(props.$index, props.row)"
                           class="remove btn-link"
                           type="danger"
                           size="sm"
                           icon>
                <i class="tim-icons icon-simple-remove"></i>
              </base-button>
            </div>
          </el-table-column>-->
        </el-table>
      </div>
      <div slot="footer"
           class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
        <div class="">
          <p class="card-category">
            顯示 {{ from + 1 }} ~ {{ to }}，共 {{ total }} 筆
          </p>
        </div>
        <base-pagination class="pagination-no-border"
                         v-model="pagination.currentPage"
                         :per-page="pagination.perPage"
                         :total="total">
        </base-pagination>
      </div>
    </card>
  </grid-item>
</template>
<script>
  import { GridItem } from 'vue-grid-layout';
  import { BasePagination } from 'src/components';
  import Fuse from 'fuse.js';
  import swal from 'sweetalert2';
  import * as moment from 'moment';
  import config from '@/config';
  import { VOLUNTEER_ORDERS_QUERY } from 'src/services/graphql';

  export default {
    components: {
      GridItem,
      BasePagination,
    },
    computed: {
      /***
       * Returns a page from the searched data or the whole data. Search is performed in the watch section below
       */
      queriedData() {
        let result = this.tableData;
        if (this.searchedData.length > 0) {
          result = this.searchedData;
        }
        return result.slice(this.from, this.to);
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      total() {
        return this.searchedData.length > 0
          ? this.searchedData.length
          : this.tableData.length;
      }
    },
    data() {
      return {
        goxDomain: config.domain.gox,
        pagination: config.pagination,
        volunteer: '',
        summary: '',
        searchQuery: '',
        propsToSearch: ['receiverMobile', 'receiverName', 'orderDetails.product.name', 'orderNumber', 'blackCatNum'],
        tableData: [],
        searchedData: [],
        fuseSearch: null,
      };
    },
    props: {
      layoutItem: {
        type: Object,
        default: null
      }
    },
    methods: {
      togglePinCard() {
        this.$emit('refreshLayout', this.layoutItem.i, 'pin', !this.layoutItem.static);
      },
      removeCardItem() {
        this.$emit('refreshLayout', this.layoutItem.i, 'remove');
      },
      reciveTimeChoiceType(type) {
        switch (type) {
          case 1:
            return '(早上配達)';
          case 2:
            return '(下午配達)';
          case 3:
            return '(晚上配達)';
          case 4:
            return '(當日配)';
          default:
            return '(不指定)';
        }
      },
      ordersFromType(type) {
        switch (type) {
          case 1:
            return '前台';
          case 2:
            return '客服';
          case 3:
            return 'APP';
          default:
            return '';
        }
      },
      temperatureLayer(type) {
        switch (type) {
          case 0:
            return '未知';
          case 1:
            return '常溫';
          case 2:
            return '冷藏';
          case 3:
            return '冷凍';
          default:
            return '';
        }
      },
      deliveryType(type) {
        switch (type) {
          case 0:
            return '未知';
          case 1:
            return '自取';
          case 2:
            return '自行配送';
          case 3:
            return '黑貓';
          case 4:
            return '全家';
          case 5:
            return '米特';
          case 6:
            return '順豐';
          default:
            return '';
        }
      },
      filterData(type) {
        switch (type) {
          case 0:
            this.tableData = [...this.orders];
            this.searchQuery = '';
            this.searchedData = [];
            break;
          case 1:
            this.tableData = this.orders
              .filter(item => item.isBaby == false && item.isXmas == false && item.direction == 0 && item.ordersStates == '處理中')
              .sort(function (x, y) {
                return moment(String(x.ordersTime)) - moment(String(y.ordersTime));
              });
            this.searchQuery = '';
            this.searchedData = [];
            break;
          case 2:
            this.tableData = this.orders.filter(item => item.isBaby == false && item.isXmas == false && item.direction == 0 && item.ordersStates == '已出貨');
            this.searchQuery = '';
            this.searchedData = [];
            break;
          case 3:
            this.tableData = this.orders.filter(item => item.isBaby == false && item.isXmas == false && item.direction == 0 && item.ordersStates == '已送達');
            this.searchQuery = '';
            this.searchedData = [];
            break;
          case 4:
            this.tableData = this.orders.filter(item => item.direction == 3);
            this.searchQuery = '';
            this.searchedData = [];
            break;
          case 5:
            this.tableData = this.orders.filter(item => item.isBaby == true || item.isXmas == true);
            this.searchQuery = '';
            this.searchedData = [];
            break;
        }
      },
      handleLike(index, row) {
        swal.fire({
          title: `You liked ${row.orderNumber}`,
          buttonsStyling: false,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-success btn-fill'
          }
        });
      },
      handleEdit(index, row) {
        window.open(this.goxDomain + '/GoX/Orders/Edit/' + row.id + '?fun_id=3034', 'greenbox');
      },
      handleDelete(index, row) {
        swal.fire({
          position: 'top',
          title: '刪除副單或單一主單',
          text: `確認取消此筆訂單`,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          confirmButtonText: '是',
          cancelButtonText: '否',
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            this.deleteRow(row);
            swal.fire({
              title: 'Deleted!',
              text: `You deleted ${row.orderNumber}`,
              icon: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
          }
        });
      },
      deleteRow(row) {
        let indexToDelete = this.tableData.findIndex(
          tableRow => tableRow.id === row.id
        );
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1);
        }
      },
      analyticsMonthlySummary(val) {
        // 統計 {各月份消費累計}
        const items = val.filter(item => item.direction == 0);
        const grouped_by_month = this.group_by_month(items).sort((a, b) => a.label.localeCompare(b.label));
        const result = [];
        result[0] = grouped_by_month;
        const thisYear = moment().year();
        for (let index = 1; index <= 3; index++) {
          result[index] = [];
          for (let month = 1; month <= 12; month++) {
            result[index][month - 1] = 0;
            let data = grouped_by_month.find(o => o.year == (thisYear - index + 1) && o.month == month);
            if (data) {
              result[index][month - 1] = data.value;
            }
          }
        }
        return result;
      },
      get_date_parts(iso_string) {
        const [year, month, day, hr, min, sec] = iso_string.split(/\D/g);
        return { year, month, day, hr, min, sec };
      },
      group_by_month(arr) {
        const result = Object.values(
          arr.reduce((a, { ordersTime: date_string, totalPrice: value }) => {
            const { year, month } = this.get_date_parts(date_string);
            const key = `${year}/${month}`;
            a[key] ?? (a[key] = { year: year, month: month, value: 0, label: key });
            a[key].value += value;
            return a;
          }, {}),
        );
        return result;
      }
    },
    mounted() { },
    apollo: {
      orders: {
        query: VOLUNTEER_ORDERS_QUERY,
        variables() {
          return {
            id: this.volunteer.id,
          }
        },
        skip() { return !this.volunteer; },
        update: result => result.volunteers.orders,
      },
    },
    watch: {
      /**
       * Searches through the table data by a given query.
       * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
       * @param value of the query
       */
      searchQuery(value) {
        let result = this.tableData;
        if (value !== '') {
          result = this.fuseSearch.search(value).map(a => a.item);
        }
        this.searchedData = result;
      },
      tableData: function (val) {
        if (val && this.fuseSearch == null) {
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
        }
        // 小計
        const sumTotalPrice = val.map(item => item.totalPrice).reduce((prev, curr) => prev + curr, 0);
        this.summary = `共 ${val.length} 筆，訂單金額加總 ${Number(sumTotalPrice).toLocaleString("zh-TW", { style: "currency", currency: "TWD" })}`;
      },
      volunteer: function (val) {
        if (val) {
          this.$apollo.queries.orders.refetch({ id: val.id, });
        }
      },
      orders: function (val) {
        console.log('orders => ' + val);
        this.tableData = val;
        // 統計 {消費趨勢分析}
        this.$emit('refreshTrendLineChart', this.analyticsMonthlySummary(val));
      },
    }
};
</script>
<style scoped>
  .pagination-select,
  .search-input {
    width: 200px;
  }
  .swal2-icon-content {
    font-size: inherit !important;
  }
</style>

<template>
  <card card-body-classes="table-full-width">
    <div>
      <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
        <base-input>
          <div>
            <el-input type="search"
                      class="mb-3 search-input"
                      clearable
                      prefix-icon="el-icon-search"
                      placeholder="搜尋"
                      v-model="searchQuery"
                      aria-controls="datatables">
            </el-input>
          </div>
        </base-input>
        <el-select class="select-primary mb-3 pagination-select"
                   v-model="pagination.perPage"
                   placeholder="Per page">
          <el-option class="select-primary"
                     v-for="item in pagination.perPageOptions"
                     :key="item"
                     :label="item"
                     :value="item">
          </el-option>
        </el-select>
      </div>
      <el-table stripe highlight-current-row height="400" v-loading="$apollo.loading" :data="queriedData">
        <el-table-column prop="ticketName"
                         label="商品劵名稱">
        </el-table-column>
        <el-table-column prop="totalAmount"
                         label="數量"
                         width="100">
        </el-table-column>
      </el-table>
    </div>
    <div slot="footer"
         class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
      <div class="">
        <p class="card-category">
          顯示 {{ from + 1 }} ~ {{ to }}，共 {{ total }} 筆
        </p>
      </div>
      <base-pagination class="pagination-no-border"
                       v-model="pagination.currentPage"
                       :per-page="pagination.perPage"
                       :total="total">
      </base-pagination>
    </div>
  </card>
</template>
<script>
  import { BasePagination } from 'src/components';
  import Fuse from 'fuse.js';
  import { VOLUNTEER_TICKET_QUERY } from 'src/services/graphql';

export default {
  components: {
    BasePagination,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['ticketName'],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
    };
  },
  props: {
    volunteer: {
      type: Object,
      default: null
    }
  },
  methods: {},
  mounted() {},
  apollo: {
    tickets: {
      query: VOLUNTEER_TICKET_QUERY,
      variables() {
        return {
          id: this.volunteer.id,
        }
      },
      skip() { return !this.volunteer; },
      update: result => result.volunteers.tickets,
    }
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery).map(a => a.item);
      }
      this.searchedData = result;
    },
    tableData: function (val) {
      if (val && this.fuseSearch == null) {
        // Fuse search initialization.
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
      }
    },
    tickets: function (val) {
      console.log('tickets => ' + val);
      this.tableData = val;
    },
  }
};
</script>
<style scoped>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content{
  font-size: inherit !important;
}
</style>

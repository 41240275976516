<template>
  <card type="chart">
    <template slot="header">
      <h5 class="card-category">客戶滿意度調查</h5>
      <h3 class="card-title">
        <i class="tim-icons icon-send text-success "></i> 12,100K
      </h3>
    </template>
    <div class="chart-area">
      <line-chart style="height: 100%"
                  :chart-data="greenLineChart.chartData"
                  :gradient-stops="greenLineChart.gradientStops"
                  :extra-options="greenLineChart.extraOptions">
      </line-chart>
    </div>
  </card>
</template>
<script>
  import LineChart from '@/components/Charts/LineChart';
  import * as chartConfigs from '@/components/Charts/config';
  import config from '@/config';

  export default {
    components: {
      LineChart,
    },
    data() {
      return {
        greenLineChart: {
          extraOptions: chartConfigs.greenChartOptions,
          chartData: {
            labels: ['七月', '八月', '九月', '十月', '十一月'],
            datasets: [
              {
                label: '滿意度指標',
                fill: true,
                borderColor: config.colors.danger,
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: config.colors.danger,
                pointBorderColor: 'rgba(255,255,255,0)',
                pointHoverBackgroundColor: config.colors.danger,
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: [90, 27, 60, 12, 80]
              }
            ]
          },
          gradientColors: [
            'rgba(66,134,121,0.15)',
            'rgba(66,134,121,0.0)',
            'rgba(66,134,121,0)'
          ],
          gradientStops: [1, 0.4, 0]
        },
      };
    },
};
</script>
<style></style>

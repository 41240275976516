<template>
  <!-- 會員資料卡、會員優惠、會員標籤 -->
  <grid-item :static="layoutItem.static" :x="layoutItem.x" :y="layoutItem.y" :w="layoutItem.w" :h="layoutItem.h" :i="layoutItem.i">
    <card class="card-user">
      <div class="author">
        <div class="block block-one"></div>
        <div class="block block-two"></div>
        <div class="block block-three"></div>
        <div class="block block-four"></div>
        <a :href="goxDomain + '/GoX/Volunteers/Edit/' + volunteer.id" target="greenbox">
          <img v-if="volunteer.pictureLink" class="avatar" :src="'https://greenbox.tw/' + volunteer.pictureLink" alt="..." />
          <img v-else-if="volunteer.gender=='先生'" class="avatar" src="img/default-avatar-cartoon-boy.png" alt="..." />
          <img v-else-if="volunteer.gender=='小姐'" class="avatar" src="img/default-avatar-cartoon-girl.png" alt="..." />
          <img v-else class="avatar" src="img/default-avatar.png" alt="..." />
        </a>
        <h4 v-if="volunteer.name" class="title">
          <strong>{{volunteer.name}}</strong>
          <span v-if="volunteer.gender!='其他'"> {{volunteer.gender}} </span>
          <el-divider direction="vertical" />
          <a href="#" @click="showDrawer('vipRecord')" class="card-link">{{volunteer.vipLevel}}</a>
          <!--<el-rate :colors="['#99A9BF', '#F7BA2A', '#FF9900']"></el-rate>-->
        </h4>
      </div>
      <div class="card-body">
        <tabs type="primary"
              tabContentClasses="tab-subcategories"
              square
              centered
              class="row">

          <tab-pane>
            <span slot="label">
              <i class="tim-icons icon-badge"></i>基本資料
            </span>
            <p v-if="volunteer">
              <i class="el-icon-user"></i>&nbsp;會員帳號：&nbsp;
              <a :href="goxDomain + '/GoX/Volunteers/Edit/' + volunteer.id" target="greenbox">
                {{volunteer.account}}
                <el-divider direction="vertical" />
                <small># {{volunteer.id}}</small>
              </a>
            </p>
            <p v-if="volunteer.mobile">
              <i class="el-icon-mobile-phone"></i>&nbsp;手機號碼：&nbsp;
              <a :href="'tel:' + volunteer.mobile">{{volunteer.mobile}}</a>
            </p>
            <p v-if="volunteer.email">
              <i class="el-icon-message"></i>&nbsp;Email：&nbsp;
              <a :href="'mailto:' + volunteer.email">{{volunteer.email}}</a>
            </p>
            <p v-if="volunteer.birthday">
              <i class="el-icon-date"></i>&nbsp;生日：&nbsp;
              <span>{{volunteer.birthday | formatDate}}</span>
              <el-divider direction="vertical" />
              <small>{{volunteer.age}} 歲</small>
            </p>
            <p v-if="volunteer.cityArea">
              <i class="el-icon-office-building"></i>&nbsp;地址：&nbsp;
              <span>{{volunteer.cityArea}} {{volunteer.address}}</span>
            </p>
            <p v-if="volunteer.orderRemarks">
              <i class="el-icon-tickets"></i>&nbsp;出貨備註：&nbsp;
              <span>{{volunteer.orderRemarks}}</span>
            </p>
            <p v-if="volunteer.remarks">
              <i class="el-icon-document-checked"></i>&nbsp;會員備註：&nbsp;
              <span>{{volunteer.remarks}}</span>
            </p>
          </tab-pane>

          <tab-pane>
            <span slot="label">
              <i class="tim-icons icon-bag-16"></i>會員優惠
            </span>
            <p>
              <i class="header-icon el-icon-coin"></i>
              購物金：
              <span v-if="volunteer.bonus">$ {{Number(volunteer.bonus).toLocaleString()}}</span>
              <span v-else>$ 0 元</span>
              <a href="#" @click="showDrawer('bonus')" class="card-link">&nbsp;<i class="el-icon-view"></i>&nbsp;<small>檢視紀錄</small></a>
            </p>
            <p>
              <i class="header-icon el-icon-present"></i>
              紅利點數：
              <span v-if="redpointSummary!=null">可用 {{Number(redpointSummary).toLocaleString()}} 點</span>
              <span v-else>0 點</span>
              <a href="#" @click="showDrawer('redpoint')" class="card-link">&nbsp;<i class="el-icon-view"></i>&nbsp;<small>檢視紀錄</small></a>
            </p>
            <p>
              <i class="header-icon el-icon-money"></i>
              商品優惠劵：
              <span v-if="couponsSummary!=null">剩餘 {{couponsSummary}} 元</span>
              <small v-else>暫無優惠劵</small>
              <a v-if="couponsSummary!=null" href="#" @click="showDrawer('coupon')" class="card-link">&nbsp;<i class="el-icon-view">&nbsp;<small>檢視紀錄</small></i></a>
            </p>
            <p>
              <i class="header-icon el-icon-refrigerator"></i>
              商品兌換劵：
              <span v-if="ticketsSummary!=null">剩餘 {{ticketsSummary}} 張</span>
              <small v-else>暫無兌換劵</small>
              <a v-if="ticketsSummary!=null" href="#" @click="showDrawer('ticket')" class="card-link">&nbsp;<i class="el-icon-view"></i>&nbsp;<small>檢視紀錄</small></a>
            </p>
          </tab-pane>

          <tab-pane>
            <span slot="label">
              <i class="tim-icons icon-tag"></i>會員標籤
            </span>
            <el-tag v-for="tag in dynamicTags" :key="tag" type="info" size="small" closable @close="handleClose(tag)">
              {{tag}}
            </el-tag>
            <el-input class="input-new-tag"
                      v-if="inputVisible"
                      v-model="inputValue"
                      ref="saveTagInput"
                      size="mini"
                      @keyup.enter.native="handleInputConfirm"
                      @blur="handleInputConfirm">
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
          </tab-pane>
        </tabs>
      </div>
    </card>
  </grid-item>
</template>
<script>
  import { GridItem } from 'vue-grid-layout';
  import { TabPane, Tabs } from 'src/components';
  import config from '@/config';

  export default {
    components: {
      GridItem,
      TabPane,
      Tabs,
    },
    data() {
      return {
        goxDomain: config.domain.gox,
        volunteer: '',
        redpointSummary: null,
        couponsSummary: null,
        ticketsSummary: null,
        dynamicTags: [],
        inputVisible: false,
        inputValue: '',
        couponsGroupingProps: {
          children: 'children',
          label: 'label'
        }
      };
    },
    props: {
      layoutItem: {
        type: Object,
        default: null
      }
    },
    methods: {
            
      showDrawer: function (type) {
        this.$emit('showDrawer', type);
      },

      handleClose(tag) {
        this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      },

      showInput() {
        this.inputVisible = true;
        this.$nextTick(_ => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      },

      handleInputConfirm() {
        let inputValue = this.inputValue;
        if (inputValue) {
          this.dynamicTags.push(inputValue);
        }
        this.inputVisible = false;
        this.inputValue = '';
      }
    },
    watch: {
      volunteer: function (val) {
        this.dynamicTags = [];
        if (val) {
          if (val.isStopCashOnDelivery) {
            this.dynamicTags.push('禁止貨到付款');
          }
          if (val.isStopPayByCash) {
            this.dynamicTags.push('禁止現金自取');
          }
          if (val.disableTime) {
            this.dynamicTags.push('黑名單');
          }
        }
      },
    }
  };
</script>
<style scoped>

  .el-tag + .el-tag {
    margin-left: 10px;
  }

  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
</style>

<template>
  <!-- Vue Grid Layout - ️A grid layout system for Vue.js -->
  <grid-layout :layout.sync="layout"
               :col-num="12"
               :row-height="30"
               :is-draggable="true"
               :is-resizable="true"
               :vertical-compact="true"
               :use-css-transforms="true">

    <!-- 會員資料卡、會員優惠、會員標籤 -->
    <volunteer-card ref="volunteerCard" @showDrawer="showDrawer" :layoutItem="layout[0]"></volunteer-card>
    <!-- 會員資料編輯 -->
    <volunteer-edit ref="volunteerEdit" @refreshLayout="refreshLayout" :layoutItem="layout[1]"></volunteer-edit>
    <!-- 訂購紀錄 -->
    <order-paginated-table ref="volunteerOrders" @refreshTrendLineChart="refreshTrendLineChart" @refreshLayout="refreshLayout" :layoutItem="layout[2]"></order-paginated-table>
    <!-- 客訴紀錄 -->
    <order-complaint-table ref="volunteerOrderComplaints" @refreshLayout="refreshLayout" :layoutItem="layout[3]"></order-complaint-table>
    <!--消費趨勢-->
    <trend-line-chart ref="trendLineChart" @refreshLayout="refreshLayout" :layoutItem="layout[5]"></trend-line-chart>
    <!--消費行為分析-->
    <grid-item :static="layout[4].static" :x="layout[4].x" :y="layout[4].y" :w="layout[4].w" :h="layout[4].h" :i="layout[4].i">
      <div class="row">
        <div class="col-lg-3 col-md-6" v-for="card in statsCards" :key="card.index">
          <stats-card :title="card.title"
                      :sub-title="card.subTitle"
                      :type="card.type"
                      :icon="card.icon">
            <div slot="footer" v-html="card.footer"></div>
          </stats-card>
        </div>
      </div>
    </grid-item>
    <grid-item v-if="!layout[6].removed" :static="layout[6].static" :x="layout[6].x" :y="layout[6].y" :w="layout[6].w" :h="layout[6].h" :i="layout[6].i">
      <div class="row">
        <div class="col-lg-7" :class="{ 'text-right': isRTL }">
          <!--消費類型分析-->
          <category-bar-chart ref="categoryBarChart"></category-bar-chart>
        </div>
        <div class="col-lg-5" :class="{ 'text-right': isRTL }">
          <!--累積使用優惠-->
          <discount-bar-chart :volunteer="volunteer"></discount-bar-chart>
        </div>
        <!--<div class="col-lg-4" :class="{ 'text-right': isRTL }">-->
        <!--客戶滿意度調查-->
          <!--<satisfaction-line-chart ref="satisfactionLineChart"></satisfaction-line-chart>
        </div>-->
      </div>
    </grid-item>
    <!--<grid-item v-if="!layout[7].removed" :static="layout[7].static" :x="layout[7].x" :y="layout[7].y" :w="layout[7].w" :h="layout[7].h" :i="layout[7].i">
      <country-map-card></country-map-card>
    </grid-item>-->
    <!--<grid-item v-for="item in layout.slice(8)"
          :key="item.i"
          :static="false"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i">
    <span class="remove" @click="removeItem(item.i)">x</span>
    <component :is="item.c" :volunteer="volunteer"></component>
    </grid-item>-->
    <el-drawer :title="(volunteer? volunteer.name : '') + ' - 購物金紀錄 - '"
               :visible.sync="bonusDrawer"
               direction="rtl"
               size="50%">
      <volunteer-bonus :volunteer="volunteer"></volunteer-bonus>
    </el-drawer>
    <el-drawer :title="(volunteer? volunteer.name : '') + ' - 紅利點數紀錄 - '"
               :visible.sync="redpointDrawer"
               direction="rtl"
               size="50%">
      <volunteer-redpoint :volunteer="volunteer"></volunteer-redpoint>
    </el-drawer>
    <el-drawer :title="(volunteer? volunteer.name : '') + ' - 剩餘優惠劵 - '"
               :visible.sync="couponDrawer"
               direction="rtl"
               size="50%">
      <volunteer-coupon :volunteer="volunteer"></volunteer-coupon>
    </el-drawer>
    <el-drawer :title="(volunteer? volunteer.name : '') + ' - 兌換劵紀錄 - '"
               :visible.sync="ticketDrawer"
               direction="rtl"
               size="50%">
      <volunteer-ticket :volunteer="volunteer"></volunteer-ticket>
    </el-drawer>
    <el-drawer :title="(volunteer? volunteer.name : '') + ' - 會員升等紀錄 - '"
               :visible.sync="vipRecordDrawer"
               direction="rtl"
               size="50%">
      <volunteer-VIPRecord :volunteer="volunteer"></volunteer-VIPRecord>
    </el-drawer>
  </grid-layout>
</template>

<script>
  import VueGridLayout from 'vue-grid-layout';
  import VolunteerCard from './VolunteerCard';
  import VolunteerEdit from './VolunteerEdit';
  import VolunteerBonus from './VolunteerBonus';
  import VolunteerRedpoint from './VolunteerRedpoint';
  import VolunteerCoupon from './VolunteerCoupon';
  import VolunteerTicket from './VolunteerTicket';
  import VolunteerVIPRecord from './VolunteerVIPRecord';
  import OrderPaginatedTable from './OrderPaginatedTable';
  import OrderComplaintTable from './OrderComplaintTable';
  import TrendLineChart from './TrendLineChart';
  import DiscountBarChart from './DiscountBarChart';
  import CategoryBarChart from './CategoryBarChart';
  import StatsCard from 'src/components/Cards/StatsCard';
  import SatisfactionLineChart from './SatisfactionLineChart';
  import PendingItemCard from './PendingItemCard';
  import CountryMapCard from './CountryMapCard';
  
  import { getWeatherForecast } from 'src/services/dataService';
  import { VOLUNTEER_QUERY, VOLUNTEER_REDPOINT_QUERY, VOLUNTEER_COUPON_QUERY, VOLUNTEER_TICKET_QUERY } from 'src/services/graphql';
  import { ANALYTICS_RFM_QUERY, CONSUMPTION_TYPE_QUERY } from 'src/services/graphql';
  import config from '@/config';

  export default {
    components: {
      GridLayout: VueGridLayout.GridLayout,
      GridItem: VueGridLayout.GridItem,
      VolunteerCard,
      VolunteerEdit,
      VolunteerBonus,
      VolunteerRedpoint,
      VolunteerCoupon,
      VolunteerTicket,
      VolunteerVIPRecord,
      OrderPaginatedTable,
      OrderComplaintTable,
      StatsCard,
      TrendLineChart,
      CategoryBarChart,
      DiscountBarChart,
      //SatisfactionLineChart,
      //PendingItemCard,
      //CountryMapCard,
    },
    data() {
      return {
        id: this.$route.params.id,
        volunteer: null,
        volunteers: [],
        statsCards: [
          {
            index: 0,
            title: '一般價值',
            subTitle: '客戶重要性',
            type: 'warning',
            icon: 'tim-icons icon-heart-2',
            footer: '<i class="tim-icons icon-trophy"></i> 根據資料模型來判斷客人類型'
          },
          {
            index: 1,
            title: '0',
            subTitle: '最近消費日',
            type: 'success',
            icon: 'tim-icons icon-single-02',
            footer: '<i class="tim-icons icon-watch-time"></i> 上次消費距今天數'
          },
          {
            index: 2,
            title: '0',
            subTitle: '消費頻率',
            type: 'primary',
            icon: 'tim-icons icon-molecule-40',
            footer: '<i class="tim-icons icon-sound-wave"></i></i> 平均訂單相隔天數'
          },
          {
            index: 3,
            title: '$0.00',
            subTitle: '消費金額',
            type: 'danger',
            icon: 'tim-icons icon-shape-star',
            footer: '<i class="tim-icons icon-coins"></i> 平均訂單消費金額'
          },
        ],
        bonusDrawer: false,
        redpointDrawer: false,
        couponDrawer: false,
        ticketDrawer: false,
        vipRecordDrawer: false,
        layout: config.layout,
        index: 0,
      };
    },
    computed: {
      enableRTL() {
        return this.$route.query.enableRTL;
      },
      isRTL() {
        return this.$rtl.isRTL;
      },
    },
    methods: {
      fillUpIframe() {
        // 調整 iframe 長寬大小
        let message = { height: (document.body.scrollHeight + 50) , width: document.body.scrollWidth };
        // window.top refers to parent window
        window.top.postMessage(message, "*");
      },
      toggleLightMode() {
        let docClasses = document.body.classList;
        docClasses.add('white-content');
      },
      getWeatherForecast() {
        getWeatherForecast().then(data => {
          console.log('getWeatherForecast => ' + data);
        });
      },
      //addItem() {
      //  // Add a new item. It must have a unique key!
      //  this.layout.push({
      //    x: 5,
      //    y: 0,
      //    w: 6,
      //    h: 13,
      //    i: this.index,
      //    c: 'VolunteerRedpoint',
      //  });
      //  // Increment the counter to ensure key is always unique.
      //  this.index++;
      //},
      //removeItem: function (val) {
      //  const index = this.layout.map(item => item.i).indexOf(val);
      //  this.layout.splice(index, 1);
      //},      
      showDrawer: function (type) {
        switch (type) {
          case 'bonus':
            this.bonusDrawer = true;
            break;
          case "redpoint":
            this.redpointDrawer = true;
            break;
          case "coupon":
            this.couponDrawer = true;
            break;
          case "ticket":
            this.ticketDrawer = true;
            break;
          case "vipRecord":
            this.vipRecordDrawer = true;
            break;
        }
      },
      refreshLayout: function (idx, type, val) {
        const index = this.layout.map(item => item.i).indexOf(idx);
        if (index > 0) {
          switch (type) {
            case 'pin':
              this.layout[index].static = val;
              break;
            case 'remove':
              //this.layout[index + 1].y -= this.layout[index].h;
              this.layout[index].removed = true;
              break;
          }
        }
      },
      refreshTrendLineChart: function (val) {
        this.$refs.trendLineChart.bigChartData = val;
        this.$refs.trendLineChart.initBigChart(0);
      },
      rfmScore(rfm) {
        if (rfm.recencyIndex == 1 && rfm.frequencyIndex == 1 && rfm.monetaryIndex == 1)
          return '重要價值';
        else if (rfm.recencyIndex == 1 && rfm.frequencyIndex == -1 && rfm.monetaryIndex == 1)
          return '重要發展';
        else if (rfm.recencyIndex == -1 && rfm.frequencyIndex == 1 && rfm.monetaryIndex == 1)
          return '重要保持';
        else if (rfm.recencyIndex == -1 && rfm.frequencyIndex == -1 && rfm.monetaryIndex == 1)
          return '重要挽留';
        else if (rfm.recencyIndex == 1 && rfm.frequencyIndex == 1 && rfm.monetaryIndex == -1)
          return '一般價值';
        else if (rfm.recencyIndex == 1 && rfm.frequencyIndex == -1 && rfm.monetaryIndex == -1)
          return '一般發展';
        else if (rfm.recencyIndex == -1 && rfm.frequencyIndex == 1 && rfm.monetaryIndex == -1)
          return '一般保持';
        else if (rfm.recencyIndex == -1 && rfm.frequencyIndex == -1 && rfm.monetaryIndex == -1)
          return '一般挽留';
      }
    },
    mounted() {
      this.i18n = this.$i18n;
      if (this.enableRTL) {
        this.i18n.locale = 'ar';
        this.$rtl.enableRTL();
      }
      this.fillUpIframe();
      this.toggleLightMode();
      //this.getWeatherForecast();
      this.index = this.layout.length;
    },
    beforeDestroy() {
      if (this.$rtl.isRTL) {
        this.i18n.locale = 'en';
        this.$rtl.disableRTL();
      }
    },
    apollo: {
      //volunteers: {
      //  query: ALL_VOLUNTEERS_QUERY,
      //  update: result => result.volunteers.volunteers,
      //},
      volunteer: {
        query: VOLUNTEER_QUERY,
        variables() {
          return {
            id: (this.id.indexOf(0) != '0' && !isNaN(this.id)) ? Number(this.id) : null,
            account: (this.id.indexOf(0) == '0' && !isNaN(this.id)) ? this.id : null,
            email: (this.id.indexOf('@') > 0 && isNaN(this.id)) ? this.id : null,
            name: (this.id.indexOf('@') <= 0 && isNaN(this.id)) ? this.id : null,
          }
        },
        skip() { return !this.$route.params.id; },
        update: result => result.volunteers.volunteer,
      },
      redpoint: {
        query: VOLUNTEER_REDPOINT_QUERY,
        variables() {
          return {
            id: this.volunteer.id,
          }
        },
        skip() { return !this.volunteer; },
        update: result => result.volunteers.redpoint,
      },
      coupons: {
        query: VOLUNTEER_COUPON_QUERY,
        variables() {
          return {
            id: this.volunteer.id,
          }
        },
        skip() { return !this.volunteer; },
        update: result => result.volunteers.coupon,
      },
      tickets: {
        query: VOLUNTEER_TICKET_QUERY,
        variables() {
          return {
            id: this.volunteer.id,
          }
        },
        skip() { return !this.volunteer; },
        update: result => result.volunteers.tickets,
      },
      rfm: {
        query: ANALYTICS_RFM_QUERY,
        variables() {
          return {
            id: this.volunteer.id,
          }
        },
        skip() { return !this.volunteer; },
        update: result => result.analytics.rfmAnalytics,
      },
      consumptionType: {
        query: CONSUMPTION_TYPE_QUERY,
        variables() {
          return {
            id: this.volunteer.id,
          }
        },
        skip() { return !this.volunteer; },
        update: result => result.analytics.consumptionType,
      },
    },
    watch: {
      '$route.params.id': function (val) {
        console.log('$route.params.id => ' + val);
        if (val) {
          this.$apollo.queries.volunteer.refetch({
            id: (val.indexOf(0) != '0' && !isNaN(val)) ? Number(val) : null,
            account: (val.indexOf(0) == '0' && !isNaN(val)) ? val : null,
            email: (val.indexOf('@') > 0 && isNaN(val)) ? val : null,
            name: (val.indexOf('@') <= 0 && isNaN(val)) ? val : null,
          });
        }
      },
      volunteer: function (val) {
        console.log('volunteer => ' + val);
        if (val) {
          this.$refs.volunteerCard.volunteer = val;
          this.$refs.volunteerEdit.volunteer = val;
          this.$refs.volunteerOrders.volunteer = val;
          this.$refs.volunteerOrderComplaints.volunteer = val;
          this.id = this.$route.params.id;

          this.$apollo.queries.redpoint.refetch({ id: val.id, });
          this.$apollo.queries.coupons.refetch({ id: val.id, });
          this.$apollo.queries.tickets.refetch({ id: val.id, });
          this.$apollo.queries.rfm.refetch({ id: val.id, });
          this.$apollo.queries.consumptionType.refetch({ id: val.id, });
        }
        else {
          this.$notify({
            type: 'primary',
            message: `查無此會員 => ${this.$route.params.id}`,
            icon: 'tim-icons icon-bell-55'
          });
        }
      },
      redpoint: function (val) {
        console.log('redpoint => ' + val);
        let summary = val.map(item => item.point).reduce((prev, curr) => prev + curr, 0);
        this.$refs.volunteerCard.redpointSummary = val.length ? summary : null;
      },
      coupons: function (val) {
        console.log('coupons => ' + val);
        let summary = val.filter(item => item.isPercent == false).map(item => (item.amount * item.count)).reduce((prev, curr) => prev + curr, 0);
        this.$refs.volunteerCard.couponsSummary = val.length ? summary : null;
      },
      tickets: function (val) {
        console.log('tickets => ' + val);
        let summary = val.map(item => item.totalAmount).reduce((prev, curr) => prev + curr, 0);
        this.$refs.volunteerCard.ticketsSummary = val.length ? summary : null;
      },
      rfm: function (val) {
        this.statsCards[0].title = this.rfmScore(val);
        this.statsCards[1].title = `${val.recency}`;
        this.statsCards[2].title = `${val.frequency}`;
        this.statsCards[3].title = Number(val.monetary).toLocaleString("zh-TW", { style: "currency", currency: "TWD" });
        this.volunteer.firstOrderTime = val.firstOrderTime;
        this.volunteer.lastOrderTime = val.lastOrderTime;
      },
      consumptionType: function (val) {
        this.$refs.categoryBarChart.blueChartData = val;
      },
    }
  };
</script>
<style scoped></style>

<template>
  <!-- 客訴紀錄 -->
  <grid-item :static="layoutItem.static" :x="layoutItem.x" :y="layoutItem.y" :w="layoutItem.w" :h="layoutItem.h" :i="layoutItem.i">
    <card card-body-classes="table-full-width">
      <h4 slot="header" class="card-title">
        <i class="el-icon-user"></i>
        <strong>{{volunteer.name}}</strong>
        客訴紀錄
        <small v-if="summary">({{summary}})</small>
        <base-button @click="togglePinCard" size="sm" class="btn float-right border-0" icon round
                     :title="layoutItem.static ? '解除固定卡片' : '固定卡片'"
                     :simple="layoutItem.static ? null : 'simple'">
          <i class="tim-icons icon-pin"></i>
        </base-button>
        <!--<base-button title="移除卡片" @click="removeCardItem" simple size="sm" class="btn float-right border-0" icon round>
          <i class="tim-icons icon-simple-remove"></i>
        </base-button>-->
      </h4>
      <div>
        <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <base-input>
            <el-input type="search"
                      class="mb-3 search-input"
                      clearable
                      prefix-icon="el-icon-search"
                      placeholder="搜尋"
                      v-model="searchQuery"
                      aria-controls="datatables">
            </el-input>
          </base-input>
          <el-button-group>
            <el-button @click="filterData(0)">ALL</el-button>
            <el-button @click="filterData(1)">未處理</el-button>
            <el-button @click="filterData(2)">已處理</el-button>
          </el-button-group>
          <el-select class="select-primary mb-3 pagination-select"
                     v-model="pagination.perPage"
                     placeholder="Per page">
            <el-option class="select-primary"
                       v-for="item in pagination.perPageOptions"
                       :key="item"
                       :label="item"
                       :value="item">
            </el-option>
          </el-select>
        </div>
        <el-table stripe highlight-current-row height="280" v-loading="$apollo.loading" :data="queriedData">
          <el-table-column type="expand">
            <template slot-scope="scope">
              <span><small>客訴編號：</small>{{ scope.row.id }}<br /></span>
              <span v-if="scope.row.insideProcess"><small>內部處理：</small>{{ scope.row.insideProcess }}<br /></span>
              <span v-if="scope.row.bonusType"><small>賠償方式：</small>{{ bonusType(scope.row.bonusType) }}<br /></span>
              <span v-if="scope.row.bonus"><small>賠償金額：</small>{{ scope.row.bonus }}<br /></span>
            </template>
          </el-table-column>
          <el-table-column label="訂單編號"
                           width="140">
            <template slot-scope="scope">
              <strong class="text-center">
                <small>訂單編號</small><br />
                <el-link :href="goxDomain + '/GoX/Orders?fun_id=3034&keyword=' + scope.row.orderNumber + '&IsBaby=0&direction=-1&fuseSearchOff=true'" target="greenbox">{{ scope.row.orderNumber }}</el-link>
              </strong>
            </template>
          </el-table-column>
          <el-table-column label="商品名稱"
                           width="160">
            <template slot-scope="scope">
              <span>{{ scope.row.productName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="客訴日期"
                           width="120">
            <template slot-scope="scope">
              <span>{{ moment(String(scope.row.complaintDate)).format('YYYY/MM/DD') }}</span>
              <br />
              <small>{{ moment(String(scope.row.complaintDate)).format('HH:mm A') }}</small>
            </template>
          </el-table-column>
          <el-table-column label="問題描述"
                           width="220">
            <template slot-scope="scope">
              <span>{{ scope.row.issue }}</span>
            </template>
          </el-table-column>
          <el-table-column label="解決方式"
                           width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.solution }}</span>
            </template>
          </el-table-column>
          <el-table-column label="狀態"
                           width="100">
            <template slot-scope="scope">
              <span>{{ (scope.row.status? '處理完成' : '未處理') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="責任歸屬"
                           width="120">
            <template slot-scope="scope">
              <span>{{ scope.row.user }}</span>
            </template>
          </el-table-column>
          <el-table-column label="賠償方式"
                           align="right">
            <template slot-scope="scope">
              <span>{{ bonusType(scope.row.bonusType) }}</span><br />
              <strong>{{ scope.row.bonus }}</strong>
            </template>
          </el-table-column>
          <!--<el-table-column align="right" label="編輯">
            <div slot-scope="props">
              <base-button @click.native="handleLike(props.$index, props.row)"
                     class="like btn-link"
                     type="info"
                     size="sm"
                     icon>
                <i class="tim-icons icon-heart-2"></i>
              </base-button>
              <base-button @click.native="handleEdit(props.$index, props.row)"
                           class="edit btn-link"
                           type="warning"
                           size="sm"
                           icon>
                <i class="tim-icons icon-pencil"></i>
              </base-button>
              <base-button @click.native="handleDelete(props.$index, props.row)"
                           class="remove btn-link"
                           type="danger"
                           size="sm"
                           icon>
                <i class="tim-icons icon-simple-remove"></i>
              </base-button>
            </div>
          </el-table-column>-->
        </el-table>
      </div>
      <div slot="footer"
           class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
        <div class="">
          <p class="card-category">
            顯示 {{ from + 1 }} ~ {{ to }}，共 {{ total }} 筆
          </p>
        </div>
        <base-pagination class="pagination-no-border"
                         v-model="pagination.currentPage"
                         :per-page="pagination.perPage"
                         :total="total">
        </base-pagination>
      </div>
    </card>
  </grid-item>
</template>
<script>
  import { GridItem } from 'vue-grid-layout';
  import { BasePagination } from 'src/components';
  import Fuse from 'fuse.js';
  import swal from 'sweetalert2';
  import * as moment from 'moment';
  import config from '@/config';
  import { VOLUNTEER_ORDERCOMPLAINTS_QUERY } from 'src/services/graphql';

  export default {
    components: {
      GridItem,
      BasePagination,
    },
    computed: {
      /***
       * Returns a page from the searched data or the whole data. Search is performed in the watch section below
       */
      queriedData() {
        let result = this.tableData;
        if (this.searchedData.length > 0) {
          result = this.searchedData;
        }
        return result.slice(this.from, this.to);
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      total() {
        return this.searchedData.length > 0
          ? this.searchedData.length
          : this.tableData.length;
      }
    },
    data() {
      return {
        goxDomain: config.domain.gox,
        volunteer: '',
        summary: '',
        pagination: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['productName', 'issue', 'solution', 'status', 'user', 'orderNumber'],
        tableData: [],
        searchedData: [],
        fuseSearch: null,
      };
    },
    props: {
      layoutItem: {
        type: Object,
        default: null
      }
    },
    methods: {
      bonusType(type) {
        switch (type) {
          case 0:
            return '補寄';
          case 1:
            return '購物金';
          case 2:
            return '退款';
          case 3:
            return '退貨';
          case 4:
            return '重寄';
          case 5:
            return '紅利點數';
          case 6:
            return '優惠券';
          case 7:
            return '無賠償';
          default:
            return '';
        }
      },
      filterData(type) {
        switch (type) {
          case 0:
            this.tableData = [...this.orderComplaints];
            this.searchQuery = '';
            this.searchedData = [];
            break;
          case 1:
            this.tableData = this.orderComplaints.filter(item => item.status == false);
            this.searchQuery = '';
            this.searchedData = [];
            break;
          case 2:
            this.tableData = this.orderComplaints.filter(item => item.status == true);
            this.searchQuery = '';
            this.searchedData = [];
            break;
        }
      },
      handleLike(index, row) {
        swal.fire({
          title: `You liked ${row.name}`,
          buttonsStyling: false,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-success btn-fill'
          }
        });
      },
      handleEdit(index, row) {
        swal.fire({
          title: `You want to edit ${row.name}`,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-info btn-fill'
          }
        });
      },
      handleDelete(index, row) {
        swal.fire({
          title: 'Are you sure?',
          text: `You won't be able to revert this!`,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          confirmButtonText: 'Yes, delete it!',
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            this.deleteRow(row);
            swal.fire({
              title: 'Deleted!',
              text: `You deleted ${row.name}`,
              icon: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
          }
        });
      },
      deleteRow(row) {
        let indexToDelete = this.tableData.findIndex(
          tableRow => tableRow.id === row.id
        );
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1);
        }
      },
      togglePinCard() {
        this.$emit('refreshLayout', this.layoutItem.i, 'pin', !this.layoutItem.static);
      },
      removeCardItem() {
        this.$emit('refreshLayout', this.layoutItem.i, 'remove');
      },
    },
    mounted() { },
    apollo: {
      orderComplaints: {
        query: VOLUNTEER_ORDERCOMPLAINTS_QUERY,
        variables() {
          return {
            id: this.volunteer.id,
          }
        },
        skip() { return !this.volunteer; },
        update: result => result.volunteers.orderComplaints,
      },
    },
    watch: {
      /**
       * Searches through the table data by a given query.
       * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
       * @param value of the query
       */
      searchQuery(value) {
        let result = this.tableData;
        if (value !== '') {
          result = this.fuseSearch.search(this.searchQuery).map(a => a.item);
        }
        this.searchedData = result;
      },
      tableData: function (val) {
        if (val && this.fuseSearch == null) {
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
        }
        // 統計 {賠償金額加總(不累加相同訂單的金額)}
        let uniqueObjArray = [...new Map(val.map((item) => [item["orderNumber"], item])).values()];
        const sumTotalBonus = uniqueObjArray.map(item => item.bonus).reduce((prev, curr) => prev + curr, 0);
        this.summary = `共 ${val.length} 件，賠償金額加總 ${Number(sumTotalBonus).toLocaleString("zh-TW", { style: "currency", currency: "TWD" })}`;
      },
      volunteer: function (val) {
        if (val) {
          this.$apollo.queries.orderComplaints.refetch({ id: val.id, });
        }
      },
      orderComplaints: function (val) {
        console.log('orderComplaints => ' + val);
        this.tableData = val;
      },
    }
  };
</script>
<style scoped>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content{
  font-size: inherit !important;
}
</style>

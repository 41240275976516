<template>
  <card type="tasks" :header-classes="{ 'text-right': isRTL }">
      <template slot="header">
        <h6 class="title d-inline">客服待辦事項 (5)</h6>
        <p class="card-category d-inline">我的待辦</p>
        <base-dropdown menu-on-right=""
                        tag="div"
                        title-classes="btn btn-link btn-icon"
                        :class="{ 'float-left': isRTL }">
          <i slot="title" class="tim-icons icon-settings-gear-63"></i>
          <a class="dropdown-item" href="#pablo"> Action </a>
          <a class="dropdown-item" href="#pablo"> Another action </a>
          <a class="dropdown-item" href="#pablo"> Something else </a>
        </base-dropdown>
      </template>
      <div class="table-full-width table-responsive">
        <task-list></task-list>
      </div>
    </card>
</template>
<script>
  import TaskList from './TaskList';

  export default {
    components: {
      TaskList,
    },
    data() {
      return {
        pendingItems: []
      };
    },
    computed: {
      enableRTL() {
        return this.$route.query.enableRTL;
      },
      isRTL() {
        return this.$rtl.isRTL;
      },
    },
    methods: {
    }
  };
</script>
<style scoped>
</style>
